
import $ from "jquery";
import { Options, Vue } from 'vue-class-component';

export default class FooterBar extends Vue {

  public async mounted() {
    window.ww.theme.initAll();
  }

  public async popSettings(){
    $("#menu-settings").showMenu();
  }
}
