import { LocallyStoredItem } from '@/services/backgroundSync';
import { UUID } from './util';

function strToDate(s: string | Date | null): Date | null {
  if (s) {
    if (typeof s === 'string') {
      return new Date(s.indexOf(' ') > 0 ? s.split(' ')[0] : s);
    }
    return s;
  }
  return null;
}

export class WritingSession implements LocallyStoredItem {
  lastModified = Date.now();
  lastSynced = 0;
  startTime = new Date();
  endTime: Date | null = null;
  phase!: ProjectPhase;
  wordCount!: number;
  projectId!: string;
  id = UUID();
  pauses: Array<{ start: Date; end: Date | null }> = [];

  get duration() {
    const end = this.endTime || new Date();
    let dur = end.getTime() - this.startTime.getTime();
    if (this.pauses) {
      this.pauses.forEach(p => {
        dur -= (p.end ? p.end?.getTime() - p.start.getTime() : 0);
      });
    }
    return Math.floor(dur / 1000);
  }

  //need setter for json parsing if using object.assign
  set duration(val: any) { Function.prototype(); }

  public static fromJSON(json: string | any): WritingSession {
    const ws = new WritingSession();
    if (typeof json === "object") {
      Object.assign(ws, json);
    } else {
      Object.assign(ws, JSON.parse(json));
    }
    ws.startTime = strToDate(ws.startTime) as Date;
    ws.endTime = strToDate(ws.endTime);   
    return ws;
  }
}

export enum ProjectStatus {
  active,
  inactive,
  abandoned,
  idea,
}

// tslint:disable-next-line: interface-name
export interface ProjectPhase {
  name: string;
  startTime: Date;
  endTime: Date;
  state: "active" | "complete" | "not started";
}

// tslint:disable-next-line: interface-name
export class Project implements LocallyStoredItem {
  id: string;
  targetWordCount: number | null = null;
  targetEndDate: Date | null = null;
  startDate: Date | null = null;
  type!: string;
  phase!: ProjectPhase;
  phases!: ProjectPhase[];
  genre!: string;
  name!: string;
  state: ProjectStatus;
  writingSessions: Array<WritingSession>;
  lastModified = 0;
  lastSynced = 0;


  constructor() {
    this.id = UUID();
    this.state = ProjectStatus.active;
    this.writingSessions = [];
  }

  public static fromJSON(json: string): Project {
    const p = new Project();
    const pObj = JSON.parse(json);
    Object.assign(p, pObj);
    p.writingSessions = p.writingSessions.map(wsObj => WritingSession.fromJSON(wsObj));
    p.startDate = strToDate(p.startDate);
    p.targetEndDate = strToDate(p.targetEndDate);
    return p;
  }

}

