// import {
//     MutationTree,
//     ActionContext,
//     ActionTree,
//     GetterTree,
//     Store as VuexStore
// } from "vuex";
import {
    WritingSession,
    Project,
    ProjectPhase,
    ProjectStatus,
} from "@/shared/projects";
import { UUID } from "@/shared/util";
import { LocallyStoredItem } from './backgroundSync';
import { LogService } from './logService';
import { set, get, entries } from 'idb-keyval';
const PREFIX = "warden_project_";

export class ProjectService {
    private static exampleProject: Project;
    private ls = new LogService();

    public async saveSession(session: WritingSession) {
        // const key = `${PREFIX}${session.projectId}_ws_${Math.round(session.startTime.getTime() / 1000)}`;
        // const sItem = session as any as LocallyStoredItem;
        // sItem.lastSynced = 0;
        // sItem.lastModified = Date.now();
        // set(key, JSON.stringify(session));
        // return key;
        const proj = await this.getProject(session.projectId) as Project;
        const sessions = proj?.writingSessions.filter(ws => ws.startTime !== session.startTime);
        sessions.push(session);
        proj.writingSessions = sessions;
        this.saveProject(proj);
    }


    public async getProject(id: string): Promise<Project | null> {
        if (id?.length > 0) {
            const projStr = await get(`${PREFIX}${id}`);
            if (projStr != null) {
                return Project.fromJSON(projStr);
            }
        }
        if (ProjectService.exampleProject && ProjectService.exampleProject.id === id) {
            return ProjectService.exampleProject;
        }
        this.ls.warn(`project not found ${id}`);
        return null;
    }

    public async getProjects(): Promise<Array<Project>> {
        const projects = [];
        const idbVals = await entries();
        for (let i = 0; i < idbVals.length; i++) {
            const key = idbVals[i][0] as string;
            if (key.indexOf(`${PREFIX}`) === 0 && key.lastIndexOf('_') == (PREFIX.length - 1)) {
                const p = JSON.parse(await get(key) as string) as Project;
                projects.push(p);
            }
        }
        if (this.isNewUser(projects)) {
            await this.loadExamples(projects);
            if (projects.length) {
                const p = projects[0];
                //p.lastModified = p.lastSynced = Date.now();//this doesn't work in js... crazy!!!
                p.lastModified = Date.now();
                p.lastSynced = p.lastModified;
                this.saveProject(projects[0]);
            }
        }
        return projects;
    }

    public saveProject(p: Project): string {
        p.id = p.id || UUID();
        p.lastModified = Date.now();
        p.lastSynced = p.lastSynced || 0;
        set(`${PREFIX}${p.id}`, JSON.stringify(p));
        return p.id;
    }

    private isNewUser(projects: Array<Project>) {
        return !projects?.length;
    }

    private async loadExamples(projects: Array<Project>): Promise<void> {
        const url = '/scripts/exampleProjects.json';
        const ex = await fetch(url);
        if (ex.ok) {
            const p = await ex.json() as Project;
            projects.push(p);
            ProjectService.exampleProject = p;
        } else {
            //TODO: show user error
            this.ls.error(`${url} ${ex.status}: ${ex.statusText}`, ex);
        }

    }
}

// // //declare state
// // export class ProjectState {
// //     projects: Array<Project> = [];
// // }


// // export enum MutationTypes {
// //     UPSERT_PROJECT = "UPSERT_PROJECT",
// //     DELETE_PROJECT = "DELETE_PROJECT"
// // }

// // export enum ActionTypes {
// //     UPSERT_PROJECT = "UPSERT_PROJECT",
// //     DELETE_PROJECT = "DELETE_PROJECT",
// // }

// // //Mutation Types
// // export type Mutations<S = ProjectState> = {
// //     [MutationTypes.UPSERT_PROJECT](state: S, payload: Project): void;
// //     [MutationTypes.DELETE_PROJECT](state: S, payload: Project): void;
// // };

// // //define mutations
// // const mutations: MutationTree<ProjectState> & Mutations = {
// //     [MutationTypes.UPSERT_PROJECT](state: ProjectState, payload: Project) {
// //         state.projects.push(payload);
// //     },
// //     [MutationTypes.DELETE_PROJECT](state: ProjectState, payload: Project) {
// //         state.projects.push(payload);
// //     }
// // };

// // //actions

// // type AugmentedActionContext = {
// //     commit<K extends keyof Mutations>(
// //         key: K,
// //         payload: Parameters<Mutations[K]>[1]
// //     ): ReturnType<Mutations[K]>;
// // } & Omit<ActionContext<ProjectState, ProjectState>, "commit">;

// // // actions interface

// // export interface Actions {
// //     [ActionTypes.UPSERT_PROJECT](
// //         { commit }: AugmentedActionContext,
// //         payload: Project
// //     ): void;
// // }

// // export const actions: ActionTree<ProjectState, ProjectState> & Actions = {
// //     [ActionTypes.UPSERT_PROJECT]({ commit }, payload: Project) {
// //         commit(MutationTypes.UPSERT_PROJECT, payload);
// //     }
// // };

// // // Getters types
// // export type Getters = {
// //     getProjectCount(state: ProjectState): number;
// // };

// // //getters

// // export const getters: GetterTree<ProjectState, ProjectState> & Getters = {
// //     getProjectCount: state => {
// //         return state.projects.length;
// //     },
// //     // currentToast: state => {
// //     //     if (state.toasts.length) {
// //     //         return state.toasts[state.toasts.length - 1];
// //     //     }
// //     //     return null;
// //     // },
// //     // currentSnack: state => {
// //     //     if (state.snacks.length) {
// //     //         return state.snacks[state.snacks.length - 1];
// //     //     }
// //     //     return null;
// //     // }
// // };