<template>
  <div id="footer-bar" class="footer-bar-1">
    <router-link to="/" exact>
      <fa icon="home" />
      <span>Home</span>
    </router-link>
    <router-link to="/projects">
      <fa icon="books" />
      <span>Projects</span>
    </router-link>
    <!-- <a href="page-contact.html"><i class="fa fa-envelope"></i><span>Contact</span></a> -->
    <!-- <a href="index-search.html"><i class="fa fa-search"></i><span>Search</span></a>-->
    <a href="#" @click="popSettings">
      <fa icon="tv" />
      <span>Display</span>
    </a>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import { Options, Vue } from 'vue-class-component';

export default class FooterBar extends Vue {

  public async mounted() {
    window.ww.theme.initAll();
  }

  public async popSettings(){
    $("#menu-settings").showMenu();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//@import "";
#footer-bar {
  svg {
    font-size: 18px;
  }
}
</style>
